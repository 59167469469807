.comp-herobanner-offre {
  background-color: #fff;
  --halo-color: #46cee2;
}
.comp-herobanner-offre__container {
  position: relative;
  top: -3em;
  display: flex;
  flex-flow: row wrap;
  gap: 2.5em;
  width: 100%;
}
@media (max-width: 1023px) {
  .comp-herobanner-offre__container {
    row-gap: 1.5em;
    flex-flow: column nowrap;
  }
}
.comp-herobanner-offre__carte {
  width: 140px;
  box-shadow: 0 4px 80px 0 var(--halo-color);
}
.comp-herobanner-offre__carte img {
  max-width: -moz-max-content;
  max-width: max-content;
}
@media (min-width: 1024px) {
  .comp-herobanner-offre__txt {
    margin: 0 2.5em;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}
.comp-herobanner-offre__title {
  font-size: 1.5rem;
  font-weight: 500;
  color: var(--color-blue-1100);
  margin-bottom: 0.2rem;
}
.comp-herobanner-offre__title p {
  line-height: 1.35;
}
.comp-herobanner-offre__title b {
  color: var(--color-brand-blue);
}
@media (max-width: 1023px) {
  .comp-herobanner-offre__title {
    font-size: 1.125em;
  }
}
.comp-herobanner-offre__legal {
  color: var(--color-blue-1100);
  font-size: 0.875em;
  line-height: 1.25;
}
.comp-herobanner-offre__link {
  white-space: nowrap;
  justify-content: flex-end;
}
.comp-herobanner-offre__item {
  backdrop-filter: blur(16px);
  background-image: linear-gradient(94deg, #ddf2f6 26%, rgba(255, 255, 255, 0.1) 98%);
  border-radius: 1em;
  display: flex;
  align-items: center;
  padding: 1.5em 2em;
  overflow: hidden;
}
@media (max-width: 1023px) {
  .comp-herobanner-offre__item {
    align-items: normal;
    flex-flow: column nowrap;
    padding: 1.5em;
    gap: 1.5em;
  }
}
.comp-herobanner-offre__item--large {
  --bg-img: url("/content/dam/hellobank-neo-aquisition/components/hero-banner-offre/prime-1180x136.webp");
  flex: 1 100%;
}
.comp-herobanner-offre__item--small {
  --bg-img: url("/content/dam/hellobank-neo-aquisition/components/hero-banner-offre/prime-304x329.webp");
  flex: 1;
}
@media (min-width: 1024px) {
  .comp-herobanner-offre__item--small .comp-herobanner-offre__txt {
    margin: 0 0 0 var(--space-32);
  }
}
@media (max-width: 1023px) {
  .comp-herobanner-offre__item--small .comp-herobanner-offre__button {
    margin-top: 1.5em;
  }
}
.comp-herobanner-offre__item--dark {
  background: var(--bg-img) no-repeat center;
  background-color: #000;
  background-size: cover;
  --halo-color: #77b4ca;
}
.comp-herobanner-offre__item--dark .comp-herobanner-offre__title, .comp-herobanner-offre__item--dark .comp-herobanner-offre__legal {
  color: #fff;
}